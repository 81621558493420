import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { isMobile } from "@100mslive/hms-video";
import {
  selectIsLocalVideoEnabled,
  selectLocalPeer,
  selectLocalPeerRole,
  selectPermissions,
  selectVideoTrackByID,
  useAVToggle,
  useDevices,
  useHMSStore,
  usePreviewJoin,
} from "@100mslive/react-sdk";
import { SettingsIcon } from "@100mslive/react-icons";
import {
  Avatar,
  Box,
  Flex,
  flexCenter,
  Loading,
  styled,
  StyledVideoTile,
  Text,
  textEllipsis,
  useBorderAudioLevel,
  useTheme,
  Video,
} from "@100mslive/react-ui";
import IconButton from "../../IconButton";
import RecordingDisabled from "../../images/recording-disabled.svg";
import RecordingDisclaimer from "../../images/recording-disclaimer.png";
import RecordingEnabled from "../../images/recording-enabled.svg";
import ToggleOff from "../../images/toggle-off.svg";
import ToggleOn from "../../images/toggle-on.svg";
import { setSession } from "../../storage/session-storage";
import { AudioVideoToggle } from "../AudioVideoToggle";
import TileConnection from "../Connection/TileConnection";
import InstructionsModal from "../Settings/InstructionsModal";
import SettingsModal from "../Settings/SettingsModal";
import WarningModal from "../Settings/warningModal";
import PreviewName from "./PreviewName";
import { VirtualBackground } from "../../plugins/VirtualBackground/VirtualBackground";
import { useUISettings } from "../AppData/useUISettings";
import {
  defaultPreviewPreference,
  UserPreferencesKeys,
  useUserPreferences,
} from "../hooks/useUserPreferences";
import { roleTypes, UI_SETTINGS } from "../../common/constants";

const Link = styled("a", {
  textDecoration: "underline",
  textUnderlineOffset: "2px",
  cursor: "pointer",
  textDecorationThickness: "0.1px",
});

export const Image = styled("img", {
  width: "100%",
  height: "auto",
  r: "$3",
});

const PreviewJoin = ({
  token,
  onJoin,
  env,
  skipPreview,
  initialName,
  asRole,
}) => {
  const urlParams = new URLSearchParams(window?.location?.search);
  const rec = urlParams.get("rec") === "true" || false;
  const [previewPreference, setPreviewPreference] = useUserPreferences(
    UserPreferencesKeys.PREVIEW,
    defaultPreviewPreference
  );
  const [name, setName] = useState(initialName || previewPreference.name);
  const { isLocalAudioEnabled, isLocalVideoEnabled } = useAVToggle();
  const permissions = useHMSStore(selectPermissions);
  const recordingPermission = !!permissions?.browserRecording;
  const [previewError, setPreviewError] = useState(false);
  const [recording, setRecording] = useState(recordingPermission && rec);
  const role = useHMSStore(selectLocalPeerRole);
  const { enableJoin, preview, join } = usePreviewJoin({
    name,
    token,
    initEndpoint: env ? `https://${env}-init.100ms.live/init` : undefined,
    initialSettings: {
      isAudioMuted: skipPreview || previewPreference.isAudioMuted,
      isVideoMuted: skipPreview || previewPreference.isVideoMuted,
      speakerAutoSelectionBlacklist: ["Yeti Stereo Microphone"],
    },
    captureNetworkQualityInPreview: true,
    handleError: (_, method) => {
      if (method === "preview") {
        setPreviewError(true);
      }
    },
    asRole,
  });
  const savePreferenceAndJoin = useCallback(() => {
    setPreviewPreference({
      name,
      isAudioMuted: !isLocalAudioEnabled,
      isVideoMuted: !isLocalVideoEnabled,
    });
    join();
    onJoin && onJoin();
  }, [
    join,
    isLocalAudioEnabled,
    isLocalVideoEnabled,
    name,
    setPreviewPreference,
    onJoin,
  ]);

  const { allDevices, selectedDeviceIDs, updateDevice } = useDevices();
  const { audioInput } = allDevices;
  const firstTimeDeviceSelected = useRef(false);

  /**
   * Function to check for default device id selection and setting
   * the device as speaker when device is selected for the first time
   */
  useEffect(() => {
    if (!isMobile && firstTimeDeviceSelected.current) {
      return;
    }
    const [defaultAudioInputDevice] = (audioInput || []).filter(device =>
      device.label.toLowerCase().includes("default")
    );
    // put check for mweb
    if (
      defaultAudioInputDevice &&
      selectedDeviceIDs.audioInput === defaultAudioInputDevice.deviceId
    ) {
      const [externalDevice] = audioInput.filter(device =>
        device.label.toLowerCase().includes("external")
      );
      const [speakerDevice] = audioInput.filter(device =>
        device.label.toLowerCase().includes("speaker")
      );
      const [bluetoothDevice] = audioInput.filter(device =>
        device.label.toLowerCase().includes("bluetooth")
      );
      const [wiredDevice] = audioInput.filter(device =>
        device.label.toLowerCase().includes("wired")
      );

      if (bluetoothDevice) {
        updateDevice({
          deviceType: "audioInput",
          deviceId: bluetoothDevice.deviceId,
        });
      } else if (wiredDevice) {
        updateDevice({
          deviceType: "audioInput",
          deviceId: wiredDevice.deviceId,
        });
      } else if (externalDevice) {
        updateDevice({
          deviceType: "audioInput",
          deviceId: externalDevice.deviceId,
        });
      } else if (speakerDevice) {
        updateDevice({
          deviceType: "audioInput",
          deviceId: speakerDevice.deviceId,
        });
      }
    }

    if (selectedDeviceIDs.audioInput && defaultAudioInputDevice?.deviceId) {
      firstTimeDeviceSelected.current = true;
    }
  }, [selectedDeviceIDs, audioInput, updateDevice, firstTimeDeviceSelected]);

  useEffect(() => {
    if (token) {
      if (skipPreview) {
        savePreferenceAndJoin();
      } else {
        preview();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, skipPreview]);

  const setRecordingState = recording => {
    setRecording(recording);
    setSession("recording", recording);
  };

  useEffect(() => {
    setRecordingState(recordingPermission && rec);
  }, [recordingPermission]);

  return (
    <Container>
      <Text
        variant="h4"
        css={{ wordBreak: "break-word", textAlign: "center", mt: "$2" }}
      >
        Get Started
      </Text>
      <Text
        css={{ c: "$textMedEmp", mt: "$2", textAlign: "center" }}
        variant="body1"
      >
        Setup your audio and video before joining
      </Text>
      <Flex
        align="center"
        justify="center"
        css={{
          "@sm": { width: "100%" },
          flexDirection: "column",
        }}
      >
        <PreviewTile name={name} error={previewError} />
        <PreviewControls
          enableJoin={enableJoin}
          savePreferenceAndJoin={savePreferenceAndJoin}
        />
        <PreviewName
          name={name}
          onChange={setName}
          enableJoin={enableJoin}
          onJoin={savePreferenceAndJoin}
        />
      </Flex>
      {recordingPermission && role?.name === "patient" && (
        <>
          <Box
            css={{
              width: "max(346px)",
              display: recording ? "none" : "",
            }}
          >
            <img src={RecordingDisclaimer} alt="chrome-img" />
          </Box>
          <Flex
            align="center"
            justify="between"
            css={{
              "@sm": { width: "100%" },
              width: "max(328px)",
              flexDirection: "row",
              ContentPosition: "between",
            }}
          >
            <Flex
              css={{
                "@sm": { width: "100%" },
                flexDirection: "row",
                ContentPosition: "start",
                mb: "$8",
              }}
            >
              <Box css={{ mr: "$6" }}>
                <img
                  src={recording ? RecordingEnabled : RecordingDisabled}
                  alt="recording-disabled"
                  width={24}
                />
              </Box>
              <Text variant="md" css={{ fontWeight: "bold" }}>
                Record Session
              </Text>
            </Flex>
            <Box css={{ cursor: "pointer", mb: "$8" }}>
              <img
                src={recording ? ToggleOn : ToggleOff}
                alt="toggle-off"
                width={44}
                onClick={() => {
                  setRecordingState(!recording);
                }}
              />
            </Box>
          </Flex>
        </>
      )}
      <Text
        css={{
          c: "$textMedEmp",
          mb: "$4",
          textAlign: "center",
          width: "max(350px)",
        }}
        variant="tiny"
      >
        By attending the call, I confirm that I have read, understood and agree
        to the{" "}
        <Link
          target="_blank"
          href="https://www.allohealth.care/legal/privacy-policy"
          rel="noreferrer"
        >
          Privacy Policy
        </Link>{" "}
        and{" "}
        <Link
          target="_blank"
          href="https://www.allohealth.care/legal/terms-and-conditions"
          rel="noreferrer"
        >
          Terms of Use
        </Link>{" "}
        listed.
      </Text>
    </Container>
  );
};

const Container = styled("div", {
  width: "100%",
  ...flexCenter,
  flexDirection: "column",
  px: "$10",
  pt: (window?.innerHeight || 0) < 684 ? "$16" : "",
});

const PreviewTile = ({ name, error }) => {
  const localPeer = useHMSStore(selectLocalPeer);
  const borderAudioRef = useBorderAudioLevel(localPeer?.audioTrack);
  const isVideoOn = useHMSStore(selectIsLocalVideoEnabled);
  const mirrorLocalVideo = useUISettings(UI_SETTINGS.mirrorLocalVideo);
  const trackSelector = selectVideoTrackByID(localPeer?.videoTrack);
  const track = useHMSStore(trackSelector);

  const {
    aspectRatio: { width, height },
  } = useTheme();
  return (
    <StyledVideoTile.Container
      css={{
        bg: "$surfaceDefault",
        aspectRatio: width / height,
        width: "unset",
        height: "min(360px, 60vh)",
        mt: "$12",
        "@sm": {
          height: "unset",
          width: "min(360px, 100%)",
          maxWidth: "100%",
        },
      }}
      ref={borderAudioRef}
    >
      {localPeer ? (
        <>
          <TileConnection name={name} peerId={localPeer.id} hideLabel={true} />
          <Video
            mirror={track?.facingMode !== "environment" && mirrorLocalVideo}
            trackId={localPeer.videoTrack}
            data-testid="preview_tile"
          />
          {!isVideoOn ? (
            <StyledVideoTile.AvatarContainer>
              <Avatar name={name} data-testid="preview_avatar_tile" />
              <Text css={{ ...textEllipsis("75%") }} variant="body2">
                {name}
              </Text>
            </StyledVideoTile.AvatarContainer>
          ) : null}
        </>
      ) : !error ? (
        <Loading size={100} />
      ) : null}
    </StyledVideoTile.Container>
  );
};

const PreviewControls = () => {
  return (
    <Flex
      justify="between"
      css={{
        width: "100%",
        mt: "$8",
      }}
    >
      <Flex css={{ gap: "$4" }}>
        <AudioVideoToggle compact />
        <VirtualBackground />
      </Flex>
      <PreviewSettings />
    </Flex>
  );
};

const PreviewSettings = React.memo(() => {
  const [open, setOpen] = useState(false);
  const role = useHMSStore(selectLocalPeerRole);
  const [openInstructions, setInstructionsOpen] = useState(true);
  const [openWarning, setWarningOpen] = useState(false);
  const permissions = useHMSStore(selectPermissions);
  const recordingPermission = !!permissions?.browserRecording;
  return (
    <Fragment>
      <IconButton
        data-testid="preview_setting_btn"
        onClick={() => setOpen(value => !value)}
      >
        <SettingsIcon />
      </IconButton>
      {open && <SettingsModal open={open} onOpenChange={setOpen} />}
      {openInstructions && role?.name === roleTypes.PATIENT && (
        <InstructionsModal
          open={openInstructions}
          onOpenChange={setInstructionsOpen}
          recordingPermission={recordingPermission}
          setWarningOpen={setWarningOpen}
        />
      )}
      {openWarning && role?.name === roleTypes.PATIENT && (
        <WarningModal open={openWarning} onOpenChange={setWarningOpen} />
      )}
    </Fragment>
  );
});

export default PreviewJoin;
