import { Fragment, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  selectIsConnectedToRoom,
  selectLocalPeerRole,
  selectPermissions,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { ExitIcon, HangUpIcon } from "@100mslive/react-icons";
import {
  Box,
  Button,
  Dialog,
  Flex,
  IconButton,
  styled,
  Text,
  Tooltip,
} from "@100mslive/react-ui";
import { ToastManager } from "./Toast/ToastManager";
import { DialogContent, DialogRow } from "../primitives/DialogContent";
import { setSession } from "../storage/session-storage";
import { useNavigation } from "./hooks/useNavigation";
import { getCookie } from "../common/helper";
import { isStreamingKit } from "../common/utils";
import {
  APPOINTMENT_STATUS_100MS_COOKIE,
  AppointmentStatus,
  appointmentTypes,
  roleTypes,
} from "../common/constants";

export const LeaveRoom = () => {
  const navigate = useNavigation();
  const [searchParams] = useSearchParams();
  const [showEndRoomModal, setShowEndRoomModal] = useState(false);
  const [lockRoom, setLockRoom] = useState(false);
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const permissions = useHMSStore(selectPermissions);
  const hmsActions = useHMSActions();
  const role = useHMSStore(selectLocalPeerRole);
  const appointmentStatus = getCookie(APPOINTMENT_STATUS_100MS_COOKIE);
  const appointmentType = searchParams.get("appointment_type");

  const redirectToLeavePage = () => {
    const token = searchParams.get("token");
    const appointmentId = searchParams.get("appointment_id");
    if (token) {
      navigate(
        `/leave?token=${token}&appointment_id=${appointmentId}&role=${role?.name}&appointment_type=${appointmentType}`
      );
    } else {
      navigate("/leave");
    }
    ToastManager.clearAllToast();
  };

  const leaveRoom = () => {
    if (role?.name === roleTypes.PATIENT) {
      setSession("recording", false);
      hmsActions.stopRTMPAndRecording();
    }
    setTimeout(() => {
      hmsActions.leave();
      redirectToLeavePage();
    }, 500);
  };

  const endRoom = () => {
    hmsActions.endRoom(lockRoom, "End Room");
    redirectToLeavePage();
  };

  const isStreamKit = isStreamingKit();
  if (!permissions || !isConnected) {
    return null;
  }

  return (
    <Fragment>
      {!permissions?.endRoom ? (
        <LeaveIconButton
          variant="danger"
          key="LeaveRoom"
          data-testid="leave_room_btn"
          css={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        >
          <Tooltip title="Leave Room">
            {!isStreamKit ? (
              <Box onClick={leaveRoom}>
                <HangUpIcon key="hangUp" />
              </Box>
            ) : (
              <Flex gap={2}>
                <Box css={{ "@md": { transform: "rotate(180deg)" } }}>
                  <ExitIcon key="hangUp" />
                </Box>
                <Text
                  css={{ "@md": { display: "none" }, color: "inherit" }}
                  variant="button"
                >
                  Leave Studio
                </Text>
              </Flex>
            )}
          </Tooltip>
        </LeaveIconButton>
      ) : (
        <>
          <Flex>
            <LeaveIconButton
              variant="danger"
              key="LeaveRoom"
              data-testid="leave_room_btn"
            >
              <Tooltip title="Leave Room">
                {!isStreamKit ? (
                  <Box onClick={() => setShowEndRoomModal(true)}>
                    <HangUpIcon key="hangUp" />
                  </Box>
                ) : (
                  <Flex gap={2}>
                    <Box css={{ "@md": { transform: "rotate(180deg)" } }}>
                      <ExitIcon key="hangUp" />
                    </Box>
                    <Text
                      css={{ "@md": { display: "none" }, color: "inherit" }}
                      variant="button"
                    >
                      Leave Studio
                    </Text>
                  </Flex>
                )}
              </Tooltip>
            </LeaveIconButton>
          </Flex>
          <Dialog.Root
            open={showEndRoomModal}
            onOpenChange={value => {
              if (!value) {
                setLockRoom(false);
              }
              setShowEndRoomModal(value);
            }}
            css={{
              width: "min(100px, 40%)",
              backgroundColor: "#222222",
              borderRadius: "$1",
            }}
          >
            <DialogContent title="You want to quit ? " Icon={ExitIcon}>
              <Text variant="md" css={{ pt: "8px" }}>
                When you end the session, it closes for everyone, and you can't
                undo it. But if you leave, others can keep going without you.
                <br />
                You can still rejoin in both cases.
              </Text>
              <DialogRow justify="between" align="center" mx="$4">
                <Button
                  variant="primary"
                  onClick={leaveRoom}
                  data-testid="lock_end_room"
                >
                  Leave Session
                </Button>
                {(appointmentStatus === AppointmentStatus.COMPLETED ||
                  appointmentType === appointmentTypes.THERAPY) && (
                  <Button
                    variant="danger"
                    onClick={endRoom}
                    data-testid="lock_end_room"
                  >
                    End Session for all
                  </Button>
                )}
              </DialogRow>
            </DialogContent>
          </Dialog.Root>
        </>
      )}
    </Fragment>
  );
};

const LeaveIconButton = styled(IconButton, {
  color: "$white",
  h: "$14",
  px: "$8",
  r: "$1",
  bg: "$error",
  "&:not([disabled]):hover": {
    bg: "$errorTint",
  },
  "&:not([disabled]):active": {
    bg: "$errorTint",
  },
  "@md": {
    px: "$4",
    mx: 0,
  },
});
