export function setSession(key, value) {
  if (typeof window !== "undefined") {
    sessionStorage.setItem(key, value);
  }
}

export function getSession(key) {
  if (typeof window !== "undefined") {
    return sessionStorage.getItem(key);
  }
}

export function removeFromSession(key) {
  return sessionStorage.removeItem(key);
}
