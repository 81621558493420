import React, { useState } from "react";
import { Box, Button, Dialog, IconButton, Text } from "@100mslive/react-ui";
import CrossWhiteIcon from "../../images/cross-white.png";
import warning1 from "../../images/warning-1.svg";
import warning2 from "../../images/warning-2.svg";
import warning3 from "../../images/warning-3.svg";
import warning4 from "../../images/warning-4.svg";
import warning5 from "../../images/warning-5.svg";
import WarningIcon from "../../images/warning-icon.svg";

export const WARNING_MESSAGES = [
  {
    img: warning1,
    eng: "Ensure that you are fully dressed and proper lighting is present.",
    hi: "(सुनिश्चित करें कि आप पूरी तरह से तैयार हैं और उचित रोशनी मौजूद है)",
  },
  {
    img: warning2,
    eng: "The call will not involve a physical examination unless the doctor says so ",
    hi: "(यदि डॉक्टर नहीं कहते हैं तो कॉल में शारीरिक परीक्षण शामिल नहीं होगा)",
  },
  {
    img: warning3,
    eng: "Maintain respectful language during the call.",
    hi: "(कॉल के दौरान सम्मानजनक भाषा बनाए रखें)",
  },
  {
    img: warning4,
    eng: "Refrain from engaging in masturbation and self-pleasure.",
    hi: "(हस्तमैथुन और आत्म-आनन्द में संलग्न होने से बचें)",
  },
  {
    img: warning5,
    eng: "Do not involve in flashing",
    hi: "(फ्लैशिंग में शामिल नहीं होना है)",
  },
];

const WarningModal = ({ open, onOpenChange, children }) => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [isLastIndex, setIsLastIndex] = useState(false);

  const handleNextCard = () => {
    const nextIndex = (currentCardIndex + 1) % WARNING_MESSAGES.length;
    setCurrentCardIndex(nextIndex);
    setIsLastIndex(nextIndex === WARNING_MESSAGES.length - 1);
  };

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content
          id="instructions-modal"
          css={{
            w: "min(450px, 90%)",
            height: "min(536px)",
            p: 0,
            r: "$4",
            overflowY: "auto",
          }}
        >
          <Box>
            <div>
              <img
                width="max(100px, 80%)"
                src={WarningIcon}
                alt="permission-img"
              />
            </div>
            <Box>
              <ol start={currentCardIndex + 1}>
                <li>
                  <Text
                    css={{ mx: "$10", my: "$4" }}
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {currentCardIndex + 1}.{"  "}
                    {WARNING_MESSAGES[currentCardIndex]?.eng}
                  </Text>
                  <Text
                    css={{ mx: "$10" }}
                    style={{
                      fontWeight: "semibold",
                    }}
                  >
                    {WARNING_MESSAGES[currentCardIndex]?.hi}
                  </Text>
                  <div
                    style={{
                      margin: "20px",
                    }}
                  >
                    <img
                      width="max(100px, 90%)"
                      src={WARNING_MESSAGES[currentCardIndex]?.img}
                      alt="warning-img"
                    />
                  </div>
                </li>
              </ol>
            </Box>
            <Box
              style={{
                position: "absolute",
                bottom: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
              css={{
                "@md": {
                  width: "100%",
                },
              }}
            >
              <div style={{ textAlign: "center", marginLeft: "20px" }}>
                {WARNING_MESSAGES.map((_, index) => (
                  <span
                    key={index}
                    style={{
                      display: "inline-block",
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      backgroundColor:
                        index === currentCardIndex ? "#4E5BA6" : "#D0D5DD",
                      margin: "0 5px",
                    }}
                  />
                ))}
              </div>
              {!isLastIndex ? (
                <Box>
                  <Button
                    variant="standard"
                    style={{
                      backgroundColor: "#222222",
                      borderRadius: "25px",
                      width: "max(200px)",
                      padding: "10px 20px",
                      margin: "0 20px",
                    }}
                    css={{
                      "@md": {
                        width: "100%",
                      },
                    }}
                    onClick={handleNextCard}
                  >
                    Next
                  </Button>
                </Box>
              ) : (
                <Box>
                  <Dialog.Close
                    css={{
                      flexShrink: 0,
                      alignContent: "center",
                      alignSelf: "center",
                      "@md": {
                        width: "100%",
                      },
                    }}
                  >
                    <Button
                      variant="standard"
                      style={{
                        backgroundColor: "#222222",
                        borderRadius: "25px",
                        width: "max(200px)",
                        padding: "10px 20px",
                        margin: "0 20px",
                      }}
                      css={{
                        "@md": {
                          width: "100%",
                        },
                      }}
                    >
                      Continue
                    </Button>
                  </Dialog.Close>
                </Box>
              )}
            </Box>
          </Box>
        </Dialog.Content>
        <Dialog.Close
          css={{
            position: "absolute",
            right: "$10",
            top: "$10",
            cursor: "pointer",
          }}
        >
          <IconButton as="div" data-testid="dialog_cross_icon">
            <img src={CrossWhiteIcon} alt="cross-white-img" />
          </IconButton>
        </Dialog.Close>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default WarningModal;
