import React from "react";
import { Box, Button, Dialog, IconButton, Text } from "@100mslive/react-ui";
import AndroidPermissions from "../../images/android-permissions.png";
import ChromeLogo from "../../images/chrome.png";
import CrossWhiteIcon from "../../images/cross-white.png";
import IosPermissions from "../../images/ios-permissions.png";
import NetworkSignal from "../../images/network-signal.png";
import RecordingInstructions from "../../images/recording-instructions.png";
import { isIOS } from "../../common/constants";

const InstructionsModal = ({
  open,
  onOpenChange,
  recordingPermission = false,
  setWarningOpen = () => {},
  children,
}) => {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content
          id="instructions-modal"
          css={{
            w: "min(800px, 90%)",
            height: "min(536px)",
            p: 0,
            r: "$4",
            overflowY: "auto",
          }}
        >
          <Box css={{ mt: "$10" }}>
            <div>
              <div></div>
              <Text css={{ m: "$10" }} variant="h6">
                Important Steps
              </Text>
            </div>
            <Text css={{ mx: "$10" }}>
              1. Grant audio and video permissions for your respective device.
            </Text>
            <Box css={{ m: "$10 $20 $10 $10" }}>
              <img
                width="max(100px, 80%)"
                src={isIOS ? IosPermissions : AndroidPermissions}
                alt="permission-img"
              />
            </Box>
            <Text css={{ m: "$10" }}>
              2. Switch to Chrome for a better experience.
            </Text>
            <Box css={{ mx: "$10" }}>
              <img src={ChromeLogo} alt="chrome-img" />
            </Box>
            <Text css={{ m: "$10" }}>
              3. Switch to a better network - video quality will be adjusted to
              avoid audio disruption.
            </Text>
            <Box css={{ m: "$10 $20 $10 $10" }}>
              <img src={NetworkSignal} alt="network-img" />
            </Box>
            {recordingPermission && (
              <>
                <Text css={{ mx: "$10" }}>
                  4. All Allo sessions are recorded for best consultation
                  experience -
                </Text>
                <Text css={{ mx: "$10" }}>100% confidential and secure.</Text>
                <Text css={{ mx: "$10" }}>
                  Disable/enable this anytime under ‘Settings’.
                </Text>
                <Box css={{ m: "$10 $20 $16 $10" }}>
                  <img src={RecordingInstructions} alt="chrome-img" />
                </Box>
              </>
            )}
            <Box css={{ m: "$10" }}>
              <Dialog.Close
                css={{
                  flexShrink: 0,
                  alignContent: "center",
                  alignSelf: "center",
                  "@md": {
                    w: "100%",
                  },
                }}
                onClick={() => setWarningOpen(true)}
              >
                <Button
                  variant="standard"
                  css={{
                    flexShrink: 0,
                    p: "$6 $9",
                    w: "max(300px)",
                    "@md": {
                      w: "100%",
                    },
                  }}
                >
                  Continue
                </Button>
              </Dialog.Close>
            </Box>
          </Box>
        </Dialog.Content>
        <Dialog.Close
          css={{
            position: "absolute",
            right: "$10",
            top: "$10",
            cursor: "pointer",
          }}
        >
          <IconButton as="div" data-testid="dialog_cross_icon">
            <img src={CrossWhiteIcon} alt="cross-white-img" />
          </IconButton>
        </Dialog.Close>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default InstructionsModal;
